export default function getTrackChangesAdapter(agreementData,currentPage) {
	return class TrackChangesIntegration {
		constructor(editor) {
			this.editor = editor;
			this.usersLoaded = false;
		}

		init() {
			const trackChangesPlugin = this.editor.plugins.get('TrackChanges');

			trackChangesPlugin.adapter = {
				getSuggestion: (suggestionId) => { 
					console.log("sad");
					let suggestion
					if(currentPage === 'editor'){
						suggestion = agreementData.trackedChanges.find(
							(obj) => obj.id === suggestionId
						);
					}
					else if(currentPage === 'summary') {
						suggestion = agreementData.summaryTrackedChanges.find(
							(obj) => obj.id === suggestionId
						);
					}
					
					let formattedSuggestion = {
						...suggestion
					}
					if (suggestion?.createdAt && typeof suggestion.createdAt === 'object' && 'seconds' in suggestion.createdAt) {
						const formattedCreatedAt = new Date(
							suggestion.createdAt.seconds * 1000
						);
						formattedSuggestion = {
							...suggestion,
							createdAt: formattedCreatedAt
						}
					}

					return Promise.resolve(formattedSuggestion);
				},

				addSuggestion: (suggestionData) => {
					
					const trackChanges =
						this.editor.plugins.get('TrackChanges');

					const suggestionsData = trackChanges.getSuggestions({
						// skipNotAttached: true,
						toJSON: true,
					});
					// suggestionData.createdAt = new Date()
					if(currentPage === 'editor'){
						agreementData.trackedChanges = suggestionsData;
					}
					else if(currentPage === 'summary') {
						agreementData.summaryTrackedChanges = suggestionsData;
					}

					return Promise.resolve({
						createdAt: new Date(),
					});
				},

				updateSuggestion: (id, suggestionData) => {
          
					const trackChanges =
						this.editor.plugins.get('TrackChanges');

					const suggestionsData = trackChanges.getSuggestions({
						// skipNotAttached: true,
						toJSON: true,
					});
					// suggestionData.createdAt = new Date()
					if(currentPage === 'editor'){
						agreementData.trackedChanges = suggestionsData;
					}
					else if(currentPage === 'summary') {
						agreementData.summaryTrackedChanges = suggestionsData;
					}
	
					return Promise.resolve();
				},
			};
		}
	};
}
