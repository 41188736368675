<script>
import { BIconDownload } from 'bootstrap-vue';

export default {
    props: ['agreementData'],
    components: {
        BIconDownload
    },
    data() {
        return {
            isMobile: false,
            isIOSChrome: false
        }
    },
    methods: {
        async downloadDocumentPage() {
            const { partner1, partner2 } = this.agreementData;
            const partner1Last = (partner1 && partner1.name.last) || '';
            const partner2Last = (partner2 && partner2.name.last) || '';
            document.title = partner1Last == partner2Last ? `${partner1Last} Financial Document Checklist` : `${partner1Last} ${partner2Last} Financial Document Checklist`;
            if (this.isIOSChrome) {
                try {
                    //added toast for iphone check
                    // this.$bvToast.toast('Please allow the browser to download the file.', {
                    //     title: 'Download',
                    //     autoHideDelay: 5000,
                    //     appendToast: true,
                    //     variant: 'info',
                    //     });
                    const content = document.querySelector('.financial-document-container');
                    
                    const printDoc = `
                        <!DOCTYPE html>
                        <html>
                        <head>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                            <style>
                                @media print {
                                    .mb-aside { display: none !important; }
                                    .print-hide { display: none !important; }
                                    .card-header { display: none !important; }
                                    .card { border: none !important; }
                                    .card-body { padding: 0 !important; }
                                    body { 
                                        -webkit-print-color-adjust: exact;
                                        print-color-adjust: exact;
                                        padding: 20px;
                                        margin: 0;
                                    }
                                    img {
                                        max-width: 100%;
                                        height: auto;
                                    }
                                    @page {
                                        margin: 0.5cm;
                                    }
                                }
                                /* Regular styles */
                                body {
                                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
                                    line-height: 1.5;
                                    color: #333;
                                }
                                ul {
                                    padding-left: 20px;
                                }
                                h4, h5 {
                                    margin-top: 1.5em;
                                    margin-bottom: 0.5em;
                                }
                            </style>
                        </head>
                        <body>
                            ${content.outerHTML}
                        </body>
                        </html>
                    `;

                    const blob = new Blob([printDoc], { type: 'text/html' });
                    const blobUrl = URL.createObjectURL(blob);

                    const printFrame = document.createElement('iframe');
                    printFrame.style.position = 'fixed';
                    printFrame.style.right = '0';
                    printFrame.style.bottom = '0';
                    printFrame.style.width = '0';
                    printFrame.style.height = '0';
                    printFrame.style.border = '0';
                    document.body.appendChild(printFrame);

                    printFrame.src = blobUrl;
                    
                    printFrame.onload = () => {
                        try {
                            printFrame.contentWindow.focus();
                            
                            setTimeout(() => {
                                printFrame.contentWindow.print();
                                
                                setTimeout(() => {
                                    URL.revokeObjectURL(blobUrl);
                                    document.body.removeChild(printFrame);
                                }, 1000);
                            }, 250);
                        } catch (e) {
                            console.error('Print failed:', e);
                            window.print();
                        }
                    };
                } catch (error) {
                    console.error('Print setup failed:', error);
                    window.print();
                }
        }
        else if (navigator.userAgent.includes('Safari')) {
            document.execCommand('print');
        }
        else {
            window.print();
        }
        },
        sectionEnabled(section) {
            return this.agreementData.agreement.sections &&
                this.agreementData.agreement.sections.includes(section);
        },
        propertyRecitals(section) {
            return this.agreementData.agreement.propertyRecitals &&
                this.agreementData.agreement.propertyRecitals.includes(section);
        }
    },
    mounted(){
        window.addEventListener('afterprint', function () {
			setTimeout(() => {
				document.title = 'Divii';
			}, 100);
		});
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.isIOSChrome = /CriOS/i.test(navigator.userAgent) && /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
    computed:{
        buttonText() {
            return this.isMobile ? 'Print' : 'Download';
        },
        hasExclusions(){
            if(this.sectionEnabled('Property')){
                const allData = [
                    ...(this.propertyRecitals('Additional Real Property') ? this.agreementData.properties || []:[]),
                    ...(this.propertyRecitals('Business')  ? this.agreementData.businesses || []:[]),
                    ...(this.propertyRecitals('Corporation')  ? this.agreementData.corporations || []:[]),
                    ...(this.propertyRecitals('Family Residence')  ? [this.agreementData.familyResidence]:[]),
                    ...(this.propertyRecitals('Financial Accounts')  ? this.agreementData.accounts || []:[]),
                    ...(this.agreementData.liabilities || []),
                    ...(this.propertyRecitals('Other Property')  ? this.agreementData.otherProperties || []:[]),
                    ...(this.propertyRecitals('Vehicles')  ? this.agreementData.vehicles || []:[]),
                    ...(this.propertyRecitals('RRSPs')  ? this.agreementData.registeredRetirementSavingsAccounts || []:[])
                ].filter(Boolean);
                return allData.some(obj => obj.hasExclusions);
            }
            else{
                return false
            }
            
        }
    }
}
</script>

<template>
    <b-card class="financial-document-container financial-document-checklist">
        <h4 slot="header" class="section-heading">Financial Documentation Checklist</h4>
        <b-button v-on:click="downloadDocumentPage"
            class="d-flex ml-auto btn-primary print-hide align-items-center financial-documents-download"><b-icon-download></b-icon-download>&nbsp;
            {{ buttonText }}</b-button>
        <div class="d-flex mt-4 flex-row justify-content-between">
        <h4 class="mt-2 mb-3 pt-0 mt-0">Summary of Documents to Gather:</h4>
        <img src="@/assets/Divii-Logo-Primary-Colour.png" alt="divii logo" width="100" height="33">
        </div>
        <div v-if="sectionEnabled('Spousal Support') || sectionEnabled('Child Support')">
            <h5 >Income Documents:</h5>
            <ul>
                <li>
                    last three income tax returns for both {{ agreementData.partner1.name.first }} and 
                    {{ agreementData.partner2.name.first }}
                </li>
                <li>
                    last three notices of assessment for both {{ agreementData.partner1.name.first }} and 
                    {{ agreementData.partner2.name.first }}
                </li>
                <li>
                    last three paystubs to show your most recent earnings for both {{ agreementData.partner1.name.first
                    }} and {{ agreementData.partner2.name.first }}
                </li>
                <li v-if="propertyRecitals('Family Residence')">
                    if you rent out a portion of your house, please determine what gross rental
                    income you receive and the expenses you deduct (generally you are able to deduct maintenance cost
                    such as heat, insurance, electricity and cleaning materials as well as your property taxes, mortgage
                    interest and CCA). The net rental income may be outlined on your income tax return, but if you do
                    not
                    claim if for tax purposes, you'll still need to include it for the purposes of calculating support.
                </li>
                <li v-if="propertyRecitals('Additional Real Property')">
                    if you rent out all or a portion of your Revenue/Vacation Property, please
                    determine what gross rental income you receive and the expenses you deduct (generally you are able
                    to
                    deduct maintenance cost such as heat, insurance, electricity and cleaning materials as well as your
                    property taxes, mortgage interest and CCA). The net rental income may be outlined on your income tax
                    return, but if you do not claim if for tax purposes, you'll still need to include it for the
                    purposes
                    of calculating support.
                </li>
                <li v-if="propertyRecitals('Business')">
                    your business documentation outlined below will also help determine what income you
                    can draw from the corporation
                </li>
                <li v-if="propertyRecitals('Corporation')">
                    your corporate documentation outlined below will also help determine what income you
                    can draw from the corporation
                </li>
            </ul>
        </div>

        <div v-if="propertyRecitals('Family Residence')">
            <h5 >Family Residence Documents:</h5>
            <ul>
                <li>
                    an appraisal showing the current value of your house (you only need to get an appraisal
                    if one of you wishes to keep the property. If you want to sell it or continue to co-own
                    it for a period of time, you don't need a market valuation)
                </li>
                <li>
                    your most current mortgage statement
                </li>
                <li>
                    your most current statements of any secured debt registered on the property title
                </li>
            </ul>
        </div>
        <div v-if="propertyRecitals('Additional Real Property')">
            <h5 >Revenue/Vacation Property Documents:</h5>
            <ul>
                <li>
                    an appraisal showing the current value of the property (you only need to get an appraisal
                    if one of you wishes to keep the property. If you want to sell it or continue to co-own it
                    for a period of time, you don't need a market valuation)
                </li>
                <li>
                    your most current mortgage statement
                </li>
                <li>
                    your most current statements of any secured debt registered on the property title
                </li>
                <li>
                    you may also wish to gather the following information:
                    <ul>
                        <li>
                            date you purchased you purchased the property
                        </li>
                        <li>
                            purchase price of the property
                        </li>
                        <li>
                            original mortgage amount
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div v-if="propertyRecitals('Business')">
            <h5 >Business Documents:</h5>
            <ul>
                <li>
                    the last three financial statements prepared
                </li>
                <li>
                    a bank statement that verifies the balances of all financial accounts in the business' name
                </li>
                <li>
                    a bank statement that verifies the balances of all liabilities that the business is responsible
                    for, like credit card statements, business loans, or lines of credit
                </li>
                <li>
                    You may also wish to have a professional business valuator prepare a business valuation, unless
                    you have a good assessment of the value of the business.
                </li>
            </ul>
        </div>

        <div v-if="propertyRecitals('Corporation')">
            <h5 >Corporate Documents:</h5>
            <ul>
                <li>
                    the last three financial statements prepared
                </li>
                <li>
                    a bank statement that verifies the balances of all financial accounts in the business' name
                </li>
                <li>
                    a bank statement that verifies the balances of all liabilities that the business is responsible
                    for, like credit card statements, business loans, or lines of credit
                </li>
                <li>
                    You may also wish to have a professional business valuator prepare a business valuation, unless
                    you have a good assessment of the value of the business.
                </li>
            </ul>

        </div>

        <div v-if="propertyRecitals('Pensions')">
            <h5 >Pension Documents:</h5>
            <ul>
                <li>
                    your most recent annual pension statement to verify the details of the pension
                </li>
                <li>
                    if you want to know the value of your pension, particularly if you want to keep it or balance
                    the value of the pension against other assets in the distribution between you and your spouse,
                    then you should get a pension valuation prepared by an actuarial accountant.
                    <ul>
                        <li>
                            A pension valuation can cost between $700-$1,200. You do not need to spend the money on
                            this if you're comfortable to divide the pension.
                        </li>
                        <li>
                            The default under the law is share the portion of the pension benefits that accrued
                            while you were living together and your Separation Agreement will outline the provisions
                            for doing this without any need for a pension valuation.
                        </li>
                        <li>
                            If you have a Federal Government pension, you can request a valuation directly from your
                            pension corporation without having to pay an actuarial accountant to value it.
                        </li>
                    </ul>
                </li>
                <li v-if="propertyRecitals('Canada Pension Plan')">
                    There is no need to gather Canada Pension Plan documentation as the Canada Pension Plan will
                    allow you to split the CPP credits that accumulated during the relationship equally. You may
                    also choose to waive your right to this option. Either way, there isn't a value that can be
                    obtained.
                </li>
            </ul>
        </div>

        <div v-if="propertyRecitals('RRSPs') || propertyRecitals('RESPs') || propertyRecitals('Financial Accounts')">
            <h5 >Financial Account Documents:</h5>
            <ul>
                <li>
                    a bank statement that verifies the balances of each financial account in either
                    {{ agreementData.partner1.name.first }}'s name ,{{ agreementData.partner2.name.first }}'s name, or
                    joint names.
                </li>
                <li>Make sure to gather this information from every bank you deal with and make sure to include:
                    <ul>
                        <li>
                            Chequing Accounts
                        </li>
                        <li>
                            Savings Accounts
                        </li>
                        <li>
                            TFSA Accounts
                        </li>
                        <li v-if="(sectionEnabled('Child Support') || sectionEnabled('Parenting')) && propertyRecitals('RESPs')">
                            RESP Accounts
                        </li>
                        <li v-if="propertyRecitals('RRSPs')">
                            RRSP Accounts
                        </li>
                        <li>
                            Investment Accounts
                        </li>
                    </ul>
                </li>
                <li>
                    if you bank online, you may have an account summary page that shows all of your accounts and the
                    balances, and sharing that document that will do so long as that document identifies each
                    account and it's balance
                </li>
            </ul>
        </div>

        <div v-if="propertyRecitals('Vehicles')">
            <h5 >Vehicle Documents:</h5>
            <ul>
                <li>
                    a current valuation of your vehicle
                </li>
                <li>
                    If you use an online tool make sure it's based in Canada and providing a valuation in Canadian
                    Dollars
                </li>
                <li>
                    <a href="https://www.carfax.ca/" target="_blank"><u>Carfax</u></a> is good option to find Canadian car prices
                </li>
                <li>
                    If you have multiple vehicles, use the same pricing tool so there's a consistent method for
                    valuation for all of your vehicles
                </li>
                <li>
                    Other vehicles like boats, motorcycles, and RVs can be more difficult to value, but online searching
                    can help establish a range of listing prices in your area
                </li>
            </ul>
        </div>

        <div v-if="propertyRecitals('Other Property')">
            <h5 >Other Property Documents:</h5>
            <ul>
                <li>
                    a current valuation of the property that you have
                </li>
                <li>
                    you may consult:
                    <ul>
                        <li>
                            a professional in your area who deals with the sale of the item
                        </li>
                        <li>
                            an auction house in your area who can assist with valuing your item
                        </li>
                        <li>
                            conduct an online search to figure out what similar items are listed for
                        </li>
                    </ul>
                </li>
                <li>
                    remember that you're trying to determine Fair Market Value, so that means what your parrticular item
                    would sell for in the marketplace
                </li>
                <li>
                    we typically don't use purchase price or replacement value
                </li>
            </ul>

        </div>
        <div v-if="hasExclusions">
            <h5 >Exclusion Documents:</h5>
            <ul>
                <li>
                    documentation outlining the value of the exclusion at the time it was received. Depending on what the exclusion is, it may be:
                    <ul>
                        <li>
                            a gift letter outlining the value of the gift and that it was just given to you (as opposed to both of you)
                        </li>
                        <li>
                            documents showing monies received through the disposition of a will and where those funds were deposited and what they were used for
                        </li>
                        <li>
                            insurance settlement outlining monies received
                        </li>
                        <li>
                            an appraisal showing the value at the date you moved in together and a mortgage statement from the same date showing how much was owing on the property (this will show the overall excluded equity at the time)
                        </li>
                    </ul>
                </li>
                <li>
                    you also need to show where those funds or value went over the years and they need to trace to a property item you currently own still
                </li>
            </ul>

        </div>
    </b-card>
</template>

<style>

.financial-document-container {
    position: relative;
    z-index: 1;
    margin:0 auto;
    padding-bottom: calc(0.7rem + env(safe-area-inset-bottom));
}

@media screen and (max-width: 760px) {
    .financial-document-container {
        margin-left: 10px;
        margin-bottom: 50px;
    }

    img {
        margin-top: 20px;
    }
}

@media print {	
    .mb-aside {
        display: none !important;
    }
    .b-toast,
    .b-toaster,
    .toast,
    .toast-header,
    .toast-body {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
        position: absolute !important;
        left: -9999px !important;
    }
	.financial-document-checklist .print-hide {
		display: none !important;
	}
    .financial-document-checklist .card-header{
        display: none !important;
    }
    .financial-document-checklist.card{
        border: none !important;
    }
    .financial-document-checklist .card-body{
        padding: 0 !important;
    }
}

</style>