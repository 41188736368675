<template>
  		<b-modal ref="downloadConfirmationModal" title="Unresolved Changes Detected"  @hidden="hideModal">
			It looks like you have some unresolved changes or comments in your document.
			Are you sure you want to proceed with downloading the draft? Any unresolved changes will not be included in the downloaded file.
			<template #modal-footer>
				<b-button
					variant="pastel-blue"
					size="sm"
					class="float-right"
					@click="showDownloadConfirmationModal=false"
				>
					Close
				</b-button>
				<b-button
					variant="primary"
					size="sm"
					@click="downloadAgreement"
				>
					Download Anyway
				</b-button>
			</template>
		</b-modal>
</template>

<script>
    export default {
        props: {
            showDownloadConfirmationModal: {
                type: Boolean,
                default: false,
            },
            draft:{
                type: Boolean,
                default: false,  
            },
            mode:{
                type:String,
                default:'editor'
            }
        },
        methods: {
            hideModal() {
                this.$emit('close');
            },
            downloadAgreement(){
                if(this.mode === 'summary'){
                    window.open(
                        `${window.location.origin}${window.location.pathname}/summary?draft=true&download=summary`
                    );
                }
                else{
                    if(this.draft){
                        window.open(`${window.location.origin}${window.location.pathname}/preview?draft=true`);
                    }else{
                        window.open(`${window.location.origin}${window.location.pathname}/preview`);
                    }
                }
                
                this.hideModal()
            },
        },
        watch:{
            showDownloadConfirmationModal(value){
                if(value){
                    this.$refs['downloadConfirmationModal'].show() 
                }
                else{
                    this.$refs['downloadConfirmationModal'].hide() 
                }
            }
        }
    };
</script>

<style>

</style>