import { render, staticRenderFns } from "./ParentalPlanner.vue?vue&type=template&id=7a8bba7b"
import script from "./ParentalPlanner.vue?vue&type=script&lang=js"
export * from "./ParentalPlanner.vue?vue&type=script&lang=js"
import style0 from "./ParentalPlanner.vue?vue&type=style&index=0&id=7a8bba7b&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports