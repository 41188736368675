<script>
import isEmail from 'validator/lib/isEmail';
import { mapState } from 'vuex';
import values from 'lodash/values';
import { BIconQuestionCircle, BSpinner, BIconTrash, BIconPencilSquare } from 'bootstrap-vue';

import authenticatedRequest from '@/lib/authenticatedRequest';
import featureEnabled from '@/mixins/featureFlag';
import permissions from '@/mixins/permissions';
import * as fb from '@/firebase';
import {hasAddPermission,hasDeletePermission} from '@/mixins/roleLevelPermissions';
import toastMessage from '@/mixins/toast';

export default {
	components: { BIconQuestionCircle ,BSpinner , BIconTrash, BIconPencilSquare},
	name: 'users',
	mixins: [featureEnabled, permissions, toastMessage],
	props: ['id'],
	data() {
		return {
			error: '',
			message: '',
			loading: false,
			newUserEmail: '',
			addingUser: false,
			deletingUserIndex:-1,
			newUserRole:null,//'client-sharing-user',// TO UPDATE // null,
			roles :[{
				text : 'Please select role',
				value : null,
				disabled: true
			},{
				text : 'Lawyer',
				value :'lawyer'
			},
			{
				text : 'Separating Party',
				value :'party'
			},
			{
				text : 'Assistant',
				value :'assistant'
			}],
			roleLevels:[],
			selectedRole: null,
			isClientUserCheck:false,
			editingUser: null,
      		isEditMode: false,
			userId: null,
			updatingUser: false,
			ownerEmail:'',
			isTempId: false,
			oldRole: '',
			
		};
	},
	computed: {
		...mapState(['user', 'permissions']),
		canAdd() {
			return isEmail(this.newUserEmail) && this.newUserRole
		},
		users() {
			console.log(values(this.permissions));
			return values(this.permissions).reduce((acc, element) => {
				if (element.owner) {
					return [element, ...acc];
				}
				return [...acc, element];
				}, []);
		},
		isUser(){
			return this.users.some(user => user.email == this.agreement.partner1.mail);
		},
		selectedRoleObj(){
			return this.roleLevels.filter((obj)=>obj.value === this.newUserRole)[0]
		},
		filteredRoleLevels(){
			let filteredRoleLevels = this.roleLevels.filter((obj) => obj.roleAssociated === this.selectedRole && !['client-sharing-user', 'lawyer-level-1', 'client-level-1'].includes(obj.roleName));
			return filteredRoleLevels = [{value: null, text: "Please select role level", disabled: true},...filteredRoleLevels];
		}
	},
	mounted() {
		this.fetchRoles()
	},
	methods: {
		hasAddPermission,
		hasDeletePermission,
		enterEditMode(user) {
			console.log("user email:", this.user.email)
			this.ownerEmail = this.users.find(item => item.hasOwnProperty('owner') && item.owner === true)?.email || '';
			console.log("Owner12:", this.ownerEmail)
			console.log("test role:", user)
			this.editingUser = { ...user };
			this.oldRole = this.role(this.editingUser.role);
			console.log("editingUser:", this.editingUser.role)
			this.newUserEmail = this.editingUser.email;
			this.selectedRole = this.role(this.editingUser.role);
			this.isClientUserCheck = this.editingUser.isClientUser || false;
			this.newUserRole = this.editingUser.role;
			this.userId = user.uid? user.uid : user.tempId;
			console.log("uid:", user.uid, user.tempId)
			this.isTempId = user.uid ? false : true;
			this.loggedInUserEmail=this.user.email
			this.isEditMode = true;
		},
		cancelEditMode() {
			this.editingUser = null;
			this.newUserEmail = '';
			this.selectedRole = null;
			this.newUserRole = null;
			this.isClientUserCheck = false;
			this.isEditMode = false;
		},
		role(role){
			if(role.includes('lawyer')){
				return 'lawyer'
			}else if(role.includes('assistant')){
				return 'assistant'
			}else if(role.includes('client')){
				return 'party'
			}else{
				return null
			}
		},
		async addUser() {
			console.log("testing:", this.user.displayName)
			let userDisplayName = '';
			if(localStorage.getItem('loginProvider') == 'GOOGLE'){
				userDisplayName = this.user.displayName + ' '+ this.user.lastName;
			}else{
				userDisplayName = '';
			}
			this.addingUser = true;
			this.error = '';
			const reqObject ={
						email: this.newUserEmail,
						uid: this.user.uid,
						role: this.newUserRole,
						host: window.location.host,
						userDisplayName: userDisplayName,
						isClientUser: this.isClientUserCheck
					}
					if(this.user.uid)
					{
					const imageUrl = `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com/o/userDocs%2F${this.user.uid}-logo.png?alt=media`;
					const diviiLogoUrl = "http://cdn.mcauto-images-production.sendgrid.net/48d4432ce91ad829/87cc08af-2b73-4b9a-b621-d10bdfdae2b1/1494x501.png";
					await this.doesImageExist(imageUrl)
						.then((exists) => {
							if (exists) {
								reqObject.logo = imageUrl
							} else {
								reqObject.logo =diviiLogoUrl;
							}
						})
						.catch((error) => {
							reqObject.logo =diviiLogoUrl;
						});
					}
				console.log(reqObject)
			try {
				const {
					data: { success, error, roleAssociated },
				} = await authenticatedRequest(
					'POST',
					`/agreements/${this.id}/addUser`,
					reqObject
				)
				console.log("checking:", roleAssociated)
				if (success) {
					this.newUserEmail = '';
					this.message = 'User was added.';
					this.$store.dispatch('fetchPermissions')
					this.message = '';
					this.$set(this.agreement, 'isAgreementShared', true);
				} else {
					if (error === 'duplicate-user') {
						this.error =
							'This user has already been added to the agreement.';
					} else if (error === 'user-not-found') {
						this.error =
							"This user doesn't have a Divii account please get them to signup for an account.";
					} else if (error === 'reselect-role') {
						console.log("role:", roleAssociated)
						this.error = `Agreement not shared, The email you tried adding is already associated with ${roleAssociated} . Please reselect and role and try again`;
					} else if (error === 'error') {
						this.error = 'Something unexpected went wrong.';
					}
				}
				this.newUserEmail = '';
				this.selectedRole = null;
				this.newUserRole = null;
				this.isClientUserCheck = false;
				this.addingUser = false;
			} catch (error) {
				console.error(error);
				console.log(error.code);
				
				this.addingUser = false;
				this.error = 'Something unexpected went wrong.';
			}
		},

		async updateUserRole() {
			console.log("update Test:", this.user.displayName, this.newUserRole, this.newUserEmail, this.userId, this.isTempId)
			let userDisplayName = localStorage.getItem('loginProvider') === 'GOOGLE' ? this.user.displayName : '';
			this.updatingUser=true;
			this.error = '';

			const reqObject = {
				email: this.newUserEmail,
				uid: this.userId,
				role: this.newUserRole,
				host: window.location.host,
				userDisplayName: userDisplayName,
				isClientUser: this.isClientUserCheck,
				ownerEmail: this.ownerEmail,
				isTempUser: this.isTempId,
				loggedInUserEmail: this.loggedInUserEmail,
				oldRole: this.oldRole,
			};

			if (this.user.uid) {
				const imageUrl = `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com/o/userDocs%2F${this.user.uid}-logo.png?alt=media`;
				const diviiLogoUrl = "http://cdn.mcauto-images-production.sendgrid.net/d700b1a78db738eb/639f4f4c-6298-49ac-865c-453e0861a165/473x207.png";

				try {
					const exists = await this.doesImageExist(imageUrl);
					reqObject.logo = exists ? imageUrl : diviiLogoUrl;
				} catch (error) {
					reqObject.logo = diviiLogoUrl;
				}
			}

			console.log("rfeq:",reqObject);

			try {
				const { data: { success, error } } = await authenticatedRequest(
					'POST',
					`/agreements/${this.id}/updateUser`,
					reqObject
				);

				if (success) {
					this.successMessage('Updated','User role was updated.')
					// this.message = 'User was updated.';
					this.newUserEmail = '';
					this.$store.dispatch('fetchPermissions');
					this.isEditMode=false;
					this.selectedRole = null;
					this.newUserRole = null;
					this.isClientUserCheck = false;
					// this.message = '';
				} else {
					if (error === 'duplicate-user') {
						this.error = 'This user has already been added to the agreement.';
					} else if (error === 'user-not-found') {
						this.error = "This user doesn't have a Divii account. Please get them to sign up for an account.";
					} else if (error === 'reselect-role') {
						this.error = `Agreement not shared, The email you tried adding is already associated with ${this.oldRole} . Please reselect and role and try again`;
					} else {
						this.error = 'Something unexpected went wrong.';
					}
				}
				this.updatingUser=false;
				
			} catch (error) {
				console.error(error);
				this.updatingUser=false;
				this.error = 'Something unexpected went wrong.';
			}
		},
		async revokeSharePermissionToUser(userToDelete,index){
			console.log('user->',userToDelete);
			if(userToDelete.role){
				this.deletingUserIndex=index;
				this.error = '';
				console.log('user To Delete->',userToDelete);
				try {
					const {
						data: { success, error },
					} = await authenticatedRequest(
						'POST',
						`/agreements/${this.id}/removeUser`,
						{
							uid: userToDelete.uid ? userToDelete.uid : userToDelete.tempId,
							role: userToDelete.role,
							email: userToDelete.email
						}
					);

					if (success) {
						this.$store.dispatch('fetchPermissions');
					} else {
						if (error) {
							this.error = 'Something unexpected went wrong.';
						}
					}
					this.deletingUserIndex=-1;
				} catch (error) {
					console.error(error);
					this.deletingUserIndex=-1;
					this.error = 'Something unexpected went wrong.';
				}
			}

		},
		assignEmail(){
			this.newUserEmail = this.agreement.partner1 ? this.agreement.partner1.mail : '';
		},
		userEmail(userEmail){
			return userEmail.replace(/^clio_import_/, '');
		},
		doesImageExist(url) {
			return new Promise((resolve) => {
				const img = new Image();

				img.src = url;
				img.onload = () => resolve(true);
				img.onerror = () => resolve(false);
			})
		},
		async fetchRoles(){
			const snap = await fb.roleLevelCollection.get();
			snap.forEach((doc) => {
				this.roleLevels.push({
					id: doc.id,
					value: doc.data().roleName,
					text: doc.data().description,
					...doc.data()
				});
			});
		},
		updateUser(user, index) {
			this.enterEditMode(user);
			const containerElement = this.$refs.containerRef;
			const mt4Element = containerElement.querySelector('.mt-4');

			if (mt4Element) {
				mt4Element.scrollIntoView({ behavior: 'smooth' });
			}
    	},
		setUserroleName(role){
			if (role === "lawyer-level-1") {
				return "Primary Lawyer";
			} else if (role === "lawyer-level-2") {
				return "Secondary Lawyer";
			} else if (role === "client-level-1") {
				return "Separating Party (Level 1)";
			} else if (role === "client-level-2") {
				return "Separating Party (Level 2)";
			} else if (role === "client-level-3") {
				return "Separating Party (Level 3)";
			} else if (role === "client-sharing-user") {
				return "Separating Party (Level 4)";
			} else {
				return role;
			}
		}
	},
	watch: {
		isClientUserCheck(newValue) {
			this.updatedVariable = newValue;
			if(newValue)
			this.newUserRole = 'client-sharing-user';
			else
			this.newUserRole = null;
		},	
		selectedRole: function () {
			if(!this.isEditMode){
				this.isClientUserCheck = false;
			}
		}
	},

};
</script>

<template>
	<b-container ref="containerRef">
		<!-- <b-card class="mb-2">
			<b-row>
				<b-col cols="1"> <b-icon-question-circle /> </b-col>

				<b-col>
					<b-card-text>
						You can add your assistant to your agreement
						</b-card-text>
				</b-col>
			</b-row>
		</b-card> -->

		
		<b-card
			v-if="hasAddPermission('share-agreement')"
			class="mt-4 mb-3"
			title="Share with Client to Fill in Initial Details"
			sub-title="Share with your client so that they may share initial details of their matter. They will only see a limited view of the Builder.  An email will be sent to them with an invitation to collaborate."
		>
			<!-- sub-title="Share this agreement with the below mentioned email id. The user will need to set up their own Divii account before you can add them to this agreement." -->
			<b-row class="mb-2">
				<b-col cols="12">
					<b-form-input
						id="new-user-email"
						v-model="newUserEmail"
						name="email"
						placeholder="Email"
						data-testid="email-to-share"
						:disabled="isEditMode"
					/>
					<span v-if="agreement.partner1 && newUserEmail != agreement.partner1.mail && !isUser" 
					:class="[(agreement.partner1 && agreement.partner1.mail && newUserEmail != agreement.partner1.mail && !isUser) ? 'suggestion-email' : 'no-padding']" @click="assignEmail">
						{{agreement.partner1.mail}}</span>
					<!-- <span v-if="agreement.partner1.mail && agreement.partner2.mail">, </span>
					<span>{{agreement.partner2.mail}}</span> -->
					<!--TEMPORARY CODE TO BE REVERTED on v1.10 start-->
					<b-form-select class="mt-3" v-model="selectedRole" :options="roles" data-testid="selected-role" :disabled="isEditMode"   @change="newUserRole = null"
					></b-form-select>					 
					<b-form-checkbox class="client-user-switch" v-if="selectedRole == 'party'" switch v-model="isClientUserCheck" size="sm" data-testid="client-user-enable">
					Send Client Information Form (Client Level 4)
					</b-form-checkbox>
					<b-form-select v-if="(selectedRole=='party' && !isClientUserCheck) || (selectedRole !='party')" class="mt-3" v-model="newUserRole" :options="filteredRoleLevels" data-testid="role-levels"></b-form-select>
					<b-alert show variant="warning" class="mt-3" v-if="selectedRoleObj && selectedRoleObj.permissions">
						<b class="alert-heading">Permissions</b>
						<div v-for="(item,index) in selectedRoleObj.permissions" :key="index" class="permissions-text">
							<div>
								<span>
									{{ (item.hasAddPermission || item.hasEditPermission || item.hasViewPermission || item.hasDeletePermission) && (item.text + '-') }}
								  </span>
								<span>
									{{
										[
										item.hasAddPermission ? 'Create' : '',
										item.hasEditPermission ? 'Edit' : '',
										item.hasViewPermission ? 'View' : '',
										item.hasDeletePermission ? 'Delete' : ''
										].filter(permission => permission !== '').join('/ ')
									}}
								</span>
							</div>
							<div v-if="item.value ==='builder'" class="ml-3">
								<div v-for="(obj, index2) in item.options" :key="index2">
									<div>
										<span>
											{{ (obj.hasAddPermission || obj.hasEditPermission || obj.hasViewPermission || obj.hasDeletePermission) && (obj.text + '-') }}
										</span>
										<span>
											{{
												[
												obj.hasAddPermission ? 'Create' : '',
												obj.hasEditPermission ? 'Edit' : '',
												obj.hasViewPermission ? 'View' : '',
												obj.hasDeletePermission ? 'Delete' : ''
												].filter(permission => permission !== '').join('/ ')
											}}
										</span>
									</div>
								</div>
							</div>
						</div>
					</b-alert> 
				</b-col>
				
			</b-row>			
			
			<b-button
				v-if="!isEditMode"
				variant="primary"
				:disabled="!canAdd || addingUser"
				@click="addUser"
				data-testid="add-email-to-share"
				class="add-btn"
				>
				<template v-if="addingUser">
					<b-spinner small type="grow"></b-spinner>
					Adding...
				</template>
				<template v-else>
					Add
				</template>
			</b-button>

			<b-row v-if="isEditMode">
				<b-col>
				  <b-button variant="primary" class="update-btn" @click="updateUserRole" :disabled="!newUserRole">
					<template v-if="updatingUser">
						<b-spinner small type="grow"></b-spinner>
						Updating...
					</template>
					<template v-else>
						Update
					</template>
				  </b-button>
				  <b-button variant="outline-secondary" class="cancel-btn" @click="cancelEditMode">Cancel</b-button>
				</b-col>
			  </b-row>
			<b-row v-if="message">
				<b-col>
					{{ message }}
				</b-col>
			</b-row>

			<b-row v-if="error" class="mt-2">
				<!-- <b-col>
					{{ error }}
				</b-col> -->
				<b-col>
					<b-alert
						show="4"
						dismissible
						fade
						variant="warning"
						@dismissed="dismissCountDown=0"
						@dismiss-count-down="5"
						>
						{{ error }}
					</b-alert>
				</b-col>
			</b-row>
		</b-card>
		<b-card
			v-for="(user, index) in users"
			:key="user.uid"
			tag="article"
			class="mt-2 mb-2"
		>
		<b-row>
			<b-col>
				<div>{{ user.owner ? user.displayName : '' }}</div>
				<div>{{ userEmail(user.email) }}</div>
				<div>
					<b>{{ user.owner ? 'Owner' : '' }}</b>
					<b>{{ user.primarySupport ? 'Primary Support' : '' }}</b>
				</div>
				<!-- <div v-if="user.status && user.role !='clientUser'">{{ user.status.toUpperCase() }}</div> -->
				<b>{{ setUserroleName(user.role) }}</b>
			</b-col>
			<b-col>
				<div class="delete">
					<b-button
						variant="pastel-blue"
						@click="revokeSharePermissionToUser(user,index)"
						:data-testid="'revoke-share-permission-to-user-'+index"
						class="delete-icon"
						v-if="user.role && hasDeletePermission('share-agreement')"
					>
						<b-spinner v-if="deletingUserIndex == index" small label="Small Spinner" type="grow"></b-spinner>
						<b-icon-trash v-else />
					</b-button>
				</div>
				<div class="update">
					<b-button
						variant="primary"
						@click="updateUser(user, index)"
						:data-testid="'update-share-permission-to-user-'+index"
						class="edit-icon"
						v-if="user.role"
					>
						<b-icon-pencil-square />
					</b-button>
				</div>
			</b-col>
		</b-row>
		</b-card>

		<!-- <b-card
			v-if="hasPermission('primaryLawyer')"
			class="md-2"
			title="Add your assistant to this file"
			sub-title="Your assistant will need to set up their own Divii account before you can add them to this agreement."
		>
			<b-row class="mb-2">
				<b-col cols="10">
					<b-form-input
						id="new-user-email"
						v-model="newUserEmail"
						name="email"
						placeholder="Email"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-button
						variant="primary"
						:disabled="!canAdd || addingUser"
						@click="addUser"
						>Add</b-button
					>
				</b-col>
			</b-row>
			<b-row v-if="message">
				<b-col>
					{{ message }}
				</b-col>
			</b-row>

			<b-row v-if="error">
				<b-col>
					{{ error }}
				</b-col>
			</b-row>
		</b-card> -->

	</b-container>
</template>

<style scoped>
.delete-icon, .edit-icon{
	margin-left: auto;
    display: block;
}

.add-btn{
	margin-left: auto;
    display: block;
}
.cancel-btn{
	background-color: #6c757d !important;
	color: #fff;
}
.update-btn,
  .cancel-btn {
    padding: 5px 10px; 
    border-radius: 5px; 
    cursor: pointer;
    margin-left: 10px;
  }

.suggestion-email{
	color: #243B42;
	font-size: 12px;
	cursor: pointer;
	box-shadow: rgba(0, 2, 0, 0.24) 1px 3px 5px;
	border-radius: 0.5rem;
	padding: 3px;
	margin-top: 5px;
}

.no-padding{
	color:#266076;
	font-size: 12px;
	cursor: pointer;
	box-shadow: rgba(0, 2, 0, 0.24) 1px 3px 5px;
	border-radius: 0.5rem;
	padding: 0px;
	margin-top: 5px;
}

.permissions-text{
	font-size: 0.85rem;
}

.client-user-switch{
	cursor: pointer;
	margin: 1rem 0;
}
.update{
	padding-top: 7px;
}

.delete-icon, .edit-icon{
	font-size: 13px;
}
</style>
