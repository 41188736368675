<script>
import { mapState } from 'vuex';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import shortid from 'shortid';
import {
	BIconPrinter,
	BIconArrowCounterclockwise,
	BIconPlus,
} from 'bootstrap-vue';
import isBefore from 'date-fns/isBefore/index';
import isAfter from 'date-fns/isAfter/index';
import round from 'lodash/round';

import Calendar from '../components/Calendar.vue';
import EditEvent from '../components/Calendar/EditEvent.vue';

import permissions from '@/mixins/permissions';
import schedules, { options } from '@/lib/schedules';
import {
	preferredName,
	andList,
} from '@/lib/agreement/filters';
import CalendarDetails from './Calendar/CalendarDetails.vue';
import getDate from 'date-fns/getDate';
import format from 'date-fns/esm/format';
import { hasEditPermission, trialActiveForClients } from '@/mixins/roleLevelPermissions';
import SubscriptionModal from '../components/SubscriptionModal.vue';


export default {
	name: 'ParentalPlanner',
	components: {
		BIconPrinter,
		BIconArrowCounterclockwise,
		BIconPlus,
		Calendar,
		CalendarDetails,
		EditEvent,
		SubscriptionModal
	},
	props:{
		tabChangeToBuilder:''
	},
	mixins: [permissions],
	mounted() {
		if(this.agreement && this.agreement.id){
			this.$store.dispatch('fetchEvents');
		}else{
			this.tabChangeToBuilder(0)
		}
		window.addEventListener('afterprint', function () {
			setTimeout(() => {
				document.title = 'Divii';
			}, 100);
		});
	},
	computed: {
		...mapState(['agreement', 'events']),
		parent1Percent() {
			return this.calculatePercent(
				this.countMinutes(
					this.events.filter((event) => event.class == 'partner1')
				)
			);
		},
		parent2Percent() {
			return this.calculatePercent(
				this.countMinutes(
					this.events.filter((event) => event.class == 'partner2')
				)
			);
		},
		unallocatedPercent() {
			return round(100 - this.parent2Percent - this.parent1Percent);
		},
		partner1Name() {
			if(this.agreement){
				const { partner1 } = this.agreement;
				return preferredName(partner1);
		}
		},
		partner2Name() {
			if(this.agreement){
				const { partner2 } = this.agreement;
				return preferredName(partner2);
			}
		},
		selectedPartnerDetails() {
			return this.selectedPartner === '1' ? 'partner1' : 'partner2';
		},
		firstEvent() {
			return this.events.find(({ start }) =>
				isBefore(start, this.beginingOfWeek1)
			);
		},
		lastEvent() {
			return this.events.find(({ end }) => isAfter(end, this.endOfWeek2));
		},
		firstAndLastAreSame() {
			return (
				this.firstEvent &&
				this.lastEvent &&
				this.firstEvent.class == this.lastEvent.class
			);
		},
		// under19Children() {
		// 	return this.agreement.children.length > 0
		// 		? andList(
		// 				childrenUnder19(this.agreement.children).map(
		// 					preferredName
		// 				)
		// 		  )
		// 		: 'the Children';
		// },
		under19Children() {
			if(this.agreement && this.agreement.children && this.agreement.children.length && 
			this.agreement.children.length > 0){
				return andList(
					this.agreement.children.filter(({ selfSupporting }) => !selfSupporting).map(
							preferredName
						)
				  )
			}else{
				return 'the Children';
			}
		},
		// parentingTimeType() {
		// 	return this.agreement.parenting.arrangement == 'Primary/Contact'
		// 		? 'contact'
		// 		: 'parenting';
		// },
		parentingTimeType() {
			// console.log("agreement:", this.agreement)
			if(this.agreement && this.agreement.parenting && this.agreement.parenting.arrangement &&
			this.agreement.parenting.arrangement == 'Primary/Contact'){
				return 'contact'
			}else{
				return 'parenting'
		}
		},
		isMobile(){
			return window.innerWidth < 768
		},
		getParentalPlannerTemplate(){
			if(this.agreement){
			const { parentalPlannerTemplate } = this.agreement;
			return parentalPlannerTemplate;// ? parentalPlannerTemplate : 'weekOnOff'
			}
		},
		sortedEvents(){
			const sortedEvents = this.events.sort((a, b) => new Date(a.start) - new Date(b.start));
			return sortedEvents
		}
	},
	data() {
		// const selectedPreset = this.getParentalPlannerTemplate ? this.getParentalPlannerTemplate : 'weekOnOff';

		return {
			selectedPartner: '1',
			selectedPreset:this.getParentalPlannerTemplate,
			schedules,
			options,
			showAddModal: false,
			editingEvent: null,
			beginingOfWeek1: new Date(2022, 0, 2, 0, 5),
			endOfWeek2: new Date(2022, 0, 15, 23, 55),
			mobile: window.matchMedia('(max-width: 575px)').matches,
			isViewMode : !hasEditPermission('parenting-planner'),
			showSubscriptionModal: false
		};
	},
	methods: {
		updateEventChange(event) {
			if(trialActiveForClients()){
				// this.showSubscriptionModal = true
				return
			}
			else{
				this.$store.dispatch('updateEvent', event);	

			}
		},
		deleteEvent(event) {
			this.$store.dispatch('deleteEvent', event);
		},
		countMinutes(events) {
			return events.reduce((accum, { start, end }) => {
				const startDate = new Date(start);
				const endDate = new Date(end);
				const minutes = differenceInMinutes(endDate, startDate);

				return (accum += minutes);
			}, 0);
		},
		calculatePercent(minutes) {
			// 20160 is the number of minutes in 2 weeks
			return round((minutes / 20160) * 100);
		},
		async changePreset(preset) {
			let accepted = false;
			if (!this.events.length) {
				accepted = true;
			} else {
				accepted = await this.showWarningModal(
					'Changing templates will remove any changes you have made to this schedule.'
				);
			}

			if (accepted) {
				this.$store.dispatch(
					'updateEvents',
					this.getScheduleTemplate(preset)
				);
				this.selectedPreset = preset;
			}
		},
		async reset() {
			let accepted = false;
			if (!this.events.length) {
				accepted = true;
			} else {
				accepted = await this.showWarningModal(
					'This action will revert the parenting time planner to its default state. Any changes you have made will not be saved.'
				);
			}
			if (accepted) {
				this.$store.dispatch('updateEvents', []);
				this.selectedPreset = null;
			}
		},
		async showWarningModal(messageText) {
			const newElement = this.$createElement;
			const messageConent = newElement(
				'div',
				{ class: ['text-center'] },
				[newElement('p', {}, messageText)]
			);
			return await this.$bvModal.msgBoxConfirm([messageConent], {
				title: 'Heads Up!',
				size: 'md',
				buttonSize: 'sm',
				okVariant: 'primary',
				cancelVariant: 'pastel-blue',
				okTitle: 'I Understand',
				cancelTitle: "I'm not ready",
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true,
			});
		},
		getScheduleTemplate(preset) {
			return this.schedules[preset].map((event) => ({
				...event,
				id: shortid.generate(),
			}));
		},
		print() {
			const { partner1, partner2 } = this.agreement;
			const partner1Last = (partner1 && partner1.name.last) || '';
			const partner2Last = (partner2 && partner2.name.last) || '';
			document.title = partner1Last == partner2Last ? `${partner1Last} Parenting Schedule` : `${partner1Last} ${partner2Last} Parenting Schedule`;
			setTimeout(() => {
				try {
					document.execCommand('print', false, null) || window.print();
				} catch (e) {
					window.print();
				}
			}, 100);
		},
		createEvent() {
			
			this.editingEvent = {
				start: new Date(2022, 0, 2, 12, 0),
				end: new Date(2022, 0, 2, 13, 0),
				id: shortid.generate(),
				class: 'partner1',
				eventNo: shortid.generate(),
			};
			this.showAddModal = true;
			// console.log('createEvent:',this.editEvent)
		},
		hideModal() {
			this.showAddModal = false;
		},
		deleteEditingEvent() {
			this.hideModal();
		},
		saveEditingEvent() {
			// If the end is before the start that means it spans multiple weeks and we need one event per week
			let uniqueId = shortid.generate()
			if (isBefore(this.editingEvent.end, this.editingEvent.start)) {
				const weekTwoEvent = {
					start: this.editingEvent.start,
					end: new Date(2022, 0, 15, 23, 59),
					class: this.editingEvent.class,
					transition: this.editingEvent.transition,
					transitionDetails: this.editingEvent.transitionDetails,
					eventNo: uniqueId,
					id: shortid.generate(),
				};
				const weekOneEvent = {
					start: new Date(2022, 0, 2, 0, 0),
					end: this.editingEvent.end,
					class: this.editingEvent.class,
					transition: this.editingEvent.transition,
					transitionDetails: this.editingEvent.transitionDetails,
					eventNo: uniqueId,
					id: shortid.generate(),
				};
				this.$store.dispatch('updateEvent', weekTwoEvent).then(() => {
					this.$store.dispatch('updateEvent', weekOneEvent);
				});
			} else {
				this.updateEventChange(this.editingEvent);
			}
		},
		loadAgreementData() {
			this.$store.dispatch('fetchAgreement', {
				id: this.$route.params.id,
			});
		},
		getWeekNumber(date) {
			return getDate(date) < 9 ? 1 : 2;
		},
		getDayOfWeek(date) {
			return date ? format(date, 'EEEE') : '';
		},
		getTime(date) {
			return date ? format(date, 'h:mm aaa') : '';
		},
		leftOrRight(event) {
			if(this.getDayOfWeek(event.start) ==='Sunday' && this.getTime(event.start)=== '12:00 am' && this.getWeekNumber(event.start) === 1){
				return 'display-none'
			}else if(this.getWeekNumber(event.start) === 1){
				return 'column-left'
			}else if(this.getWeekNumber(event.start) === 2){
				return 'column-right'
			}	
		},
		trialActiveForClients,
		triggerShowSubscriptionModal(){
			this.showSubscriptionModal = true
		}
	},
	watch:{
		selectedPreset(value){
			this.loadAgreementData();
			this.$store.dispatch('updateParentalPlannerTemplate', value);
		}
	}
};
</script>

<style>
.square {
	width: 20px;
	height: 20px;
}
.partner1 {
	background-color: #9CB6BD;
}
.partner2 {
	background-color: #3F6772;
}
.partner3 {
	background-color: #6D757C;
}
.border-primary {
	border-color: #9CB6BD !important;
}
.border-secondary {
	border-color: #3F6772 !important;
}

.grey {
	background-color: lightgray;
}

.parenting-wrapper {
	overflow-x: hidden;
}
.hide-scrollbars {
	overflow-x: hidden;
	overflow-y: scroll;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.hide-scrollbars::-webkit-scrollbar {
	display: none;
	height: 0;
	width: 0;
	background: transparent;
}
@media print {
	.print-scroll::-webkit-scrollbar {
	display: none;
	height: 0;
	width: 0;
	background: transparent;
	}
}
#two-week-title {
	color: #3F6772
;
    padding-top: 10px;
}

::-webkit-scrollbar {
     width: 1.25rem;
}
::-webkit-scrollbar-thumb {
     background-color: #D5E0E4;
     border-radius: 1.25rem;
     height: 6.25rem;
     width: 0.875rem;
     border: 3px solid rgba(0, 0, 0, 0);
     background-clip: padding-box;
     -webkit-border-radius: 1.25rem;
}
@media print{
	.print-margin{
		margin-top: 5px;
	}
  *{
    overflow: visible !important;
  }
  #two-week-title {
	display: none;
  }
  .transition-details{
	display: block !important;
  }

  @page {
    margin: 1cm;
    @bottom-right {
      content:"Page " counter(page) " of  " counter(pages);
    }
  }

}
.grid-column{
	display: grid;
	grid-template-columns: 1fr 1fr;
  	grid-auto-flow: column;
}
.column-left {
  grid-column: 1; 
}
.column-right {
  grid-column: 2;
}
.display-none{
	display: none;
}


.transition-details{
	display: none;
	padding-top: 1px;
}

.page-number{
	display: none;
	text-align: right;
}

@media print{
	.no-print{
		display: none;
	}
}

</style>

<template>
	<div class="parenting-wrapper custom-scroll print-scroll" v-bind:class="{'scroll-small': isMobile,'no-print': trialActiveForClients()}">
		<b-card class="custom-header-wrapper mt-2">
			<h4 slot="header" class="section-heading">
				{{
					parentingTimeType == 'contact'
						? 'Contact Time Schedule'
						: 'Parenting Time Schedule'
				}}
			</h4>
		</b-card>
		<div class="hide-scrollbars print-hide-scrollbar">
			<edit-event
				title="Add Event"
				:show-edit-modal="showAddModal"
				:hide-modal="hideModal"
				:save-event="saveEditingEvent"
				:editing-event="editingEvent"
				:partner1-name="partner1Name"
				:partner2-name="partner2Name"
				:delete-editing-event="deleteEditingEvent"
			/>
			<b-row>
				<b-col class="mr-auto print-margin" cols="auto">
					<h4 id="two-week-title">Two Week Rotation</h4>
				</b-col>
				<b-col cols="auto print-hide my-2">
						<!-- v-if="hasPermission('primaryLawyer')" -->
					<b-dropdown
						id="schedule-options"
						text="Templates"
						class="m-md-2 print-hide"
						variant="primary"
						data-testid="parental-planner-schedule-options"	
						:disabled="isViewMode"					
					>
						<b-dropdown-item
							v-for="(option, index) in options"
							:key="option.id"
							:active="getParentalPlannerTemplate === option.id"
							@click="changePreset(option.id)"
							:data-testid="'parental-planner-templates-'+index"
						>
							{{ option.label }}
						</b-dropdown-item>
					</b-dropdown>

					<b-button-group>
							<!-- v-if="hasPermission('primaryLawyer')" -->
						<b-button
							variant="primary"
							@click="trialActiveForClients()? triggerShowSubscriptionModal():createEvent()"
							data-testid="parental-planner-add-time-block"
							:disabled="isViewMode"
							:class="{'btn-disabled-but-clickable': trialActiveForClients()}"

						>
							<b-icon-plus /> Add Timeblock
						</b-button>
						<b-button variant="outline-primary" @click="trialActiveForClients()? triggerShowSubscriptionModal():print()" data-testid="parental-planner-print-button" 
							:class="{'btn-disabled-but-clickable': trialActiveForClients()}"
						>
							<b-icon-printer />
						</b-button>
							<!-- v-if="hasPermission('primaryLawyer')" -->
						<b-button
							variant="primary"
							@click="reset"
							data-testid="parental-planner-reset"
							:disabled="isViewMode"
						>
							<b-icon-arrow-counterclockwise />
						</b-button>
					</b-button-group>
				</b-col>
			</b-row>
			<calendar
				:update-event-change="updateEventChange"
				:delete-event="deleteEvent"
				:events="events"
				:selected-partner-details="selectedPartnerDetails"
				:partner1-name="partner1Name"
				:partner2-name="partner2Name"
				:first-and-last-are-same="firstAndLastAreSame"
				:first-event="firstEvent"
				:last-event="lastEvent"
				:disabled="isViewMode"
			/>
			<b-row class="my-3">
				<b-col>
					<b-row>
						<b-col cols="1"
							><b-card class="square partner1" /> </b-col
						><b-col cols
							>{{ partner1Name }} {{ parent1Percent }}%</b-col
						>
					</b-row>
				</b-col>
				<b-col>
					<b-row>
						<b-col cols="1">
							<b-card class="square partner2" />
						</b-col>
						<b-col cols>
							{{ partner2Name }} {{ parent2Percent }}%
						</b-col>
					</b-row>
				</b-col>
				<b-col v-if="unallocatedPercent > 1">
					<b-row>
						<b-col cols="1"><b-card class="square grey" /> </b-col>
						<b-col cols>
							Unallocated
							{{ unallocatedPercent }}%
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-card class="custom-header-wrapper mt-2 pb-3 transition-details">
			<h4 slot="header">
				Transition Details
			</h4>
			</b-card>
			<b-row
				v-if="sortedEvents && sortedEvents.length"
				class="mb-2 grid-column"
				:style="`max-height: ${!mobile ? '515px' : '700px'}`"
			>
				<b-col v-for="event in sortedEvents" :key="event.id" :cols="12" :class="leftOrRight(event)">
					<b-card
						v-if="
							!firstEvent ||
							(firstEvent && event.id != firstEvent.id) || event
						"
						class="my-2 p-2"
						no-body
						:border-variant="
							event.class == 'partner1' ? 'primary' : 'secondary'
						"
					>
						<calendar-details
							:event="event"
							:partner1-name="partner1Name"
							:partner2-name="partner2Name"
							:first-and-last-are-same="firstAndLastAreSame"
							:first-event="firstEvent"
							:last-event="lastEvent"
							:children="under19Children"
							:parenting-time-type="parentingTimeType"
						/>
					</b-card>
				</b-col>
			</b-row>
		</div>
		<subscription-modal :showSubscriptionModal="showSubscriptionModal" @close="showSubscriptionModal = false"></subscription-modal>
	</div>
</template>
