<script>
import childNameOrChildren from '@/mixins/childNameOrChildren';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';
import { mapState } from 'vuex';

export default {
	mixins: [childNameOrChildren],
	props: ['agreementData'],
	data() {
		return { 
			isViewMode : !hasEditPermission('section-parenting')
		};	
	},
	computed: {
		...mapState(['currentRoleLevel']),
		parenting() {
			return this.agreementData.parenting;
			// return this.agreementData.parenting =  this.agreementData.parenting.hasOwnProperty('additionalClausesParentingAgreementsToRecitals') 
			// 	? this.agreementData.parenting 
			// 	: {...this.agreementData.parenting, additionalClausesParentingAgreementsToRecitals: []};
		},
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		},
		dynamicQuestions(){
			if(this.isUserSeparatingParty){
				return {
					nestingArrangement: "Will you use a Nesting Arrangement for a period of time?",
					nestingResidence: "Where will each parent reside when it is not their parenting time?",
					facilitatedCommunication: `Will you faciliate communication between the parents and ${this.childNameOrChildren} during parenting time?`
				}
			}
			else{
				return {
					nestingArrangement: `Are the parents intending to exercise parenting time in a nesting arrangement where ${this.childNameOrChildren} will remain in the Family Residence and the parents will come and go according to the Parenting Time Schedule?`,
					nestingResidence: "Where will the parties reside when it is not their Parenting Time?",
					facilitatedCommunication: `Will the parties facilitate communication between ${this.childNameOrChildren} and the other party during parenting time?`
				}
			}
		}
	},
	mounted(){
		this.agreementData.parenting =  this.agreementData.parenting.hasOwnProperty('additionalClausesParentingAgreementsToRecitals') 
			? this.agreementData.parenting 
			: {...this.agreementData.parenting, additionalClausesParentingAgreementsToRecitals: []};
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Parenting Agreements</h4>

		<yes-no-choice
			v-model="parenting.nestingArrangement"
			class="mb-3"
			:label="dynamicQuestions.nestingArrangement"
			reset-undefined
			:testid="'parenting-agreement-child-communication'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="parenting.nestingArrangement"
		>
			<radio-group
				v-model="parenting.nestingResidence"
				select
				class="mt-3"
				reset-undefined
				input-cols="8"
				:label="dynamicQuestions.nestingResidence"
				:options="[
					'Each will maintain their own separate residence',
					'The parties will share a second residence',
				]"
				:testid="'parenting-agreement-reside-not-parenting-time'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="
					parenting.nestingResidence ===
					'The parties will share a second residence'
				"
			>
				<radio-group
					v-model="parenting.nestingSecondResidencePlan"
					class="mb-3"
					reset-undefined
					input-cols="8"
					label="How will the parties share the cost of the Shared Second Residence?"
					select
					:options="[
						{
							text: 'Equally between them',
							value: 'equally between them',
						}, // value fix
						{
							text: 'As agreed between them',
							value: 'as agreed between them',
						},
						{
							text: 'In proportion to their incomes as calculated under the Federal Child Support Guidelines',
							value: 'in proportion to their incomes as calculated under the Federal Child Support Guidelines',
						},
					]"
					:testid="'parenting-agreement-shared-second-residence-cost-share'"
					:length="'long'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<check-group
				v-model="parenting.nestingSecondResidenceUpkeep"
				reset-undefined
				class="mt-3"
				label="What expectations of shared household upkeep would you like to include?"
				other
				:options="[
					{
						text: 'Leaving the kitchen clean, organized, and with clean dishes',
						value: 'leaving the kitchen clean, organized, and with clean dishes',
					}, // value fix
					{
						text: `Removing food from the refrigerator that is not for ${childNameOrChildren}`,
						value: `removing food from the refrigerator that is not for ${childNameOrChildren}`,
					},
					{
						text: 'Cleaning the bathroom for the other parent’s use',
						value: 'cleaning the bathroom for the other parent’s use',
					},
					{
						text: 'Removing the sheets from the bed in the master bedroom',
						value: 'removing the sheets from the bed in the master bedroom',
					},
				]"
				:testid="'parenting-agreement-nesting-second-residence-up-keep'"
				:id="'parenting-agreement-nesting-second-residence-up-keep'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="parenting.facilitatedCommunication"
			class="mb-3"
			:label="dynamicQuestions.facilitatedCommunication"
			reset-undefined
			:testid="'parenting-agreement-facilitate-communication'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="parenting.facilitatedCommunication"
		>
			<check-group
				v-model="parenting.facilitatedCommunicationMethods"
				class="mb-3"
				reset-undefined
				:options="[
					{ text: 'Telephone', value: 'telephone calls' }, // value fix
					{ text: 'Text message', value: 'text messages' },
					{ text: 'Video call', value: 'video calls' },
				]"
				label="By what method of communication?"
				other
				:testid="'parenting-agreement-method-of-communication'"
				:id="'parenting-agreement-method-of-communication'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="parenting.facilitatedCommunicationWhen"
				reset-undefined
				:options="[
					{
						text: `At times determined by ${childNameOrChildren}`, // value fixs very hard
						value: `at times determined by ${childNameOrChildren}`,
					},
					{
						text: 'When the other parent makes contact',
						value: 'when the other parent makes contact',
					},
					{
						text: 'As agreed between the parties',
						value: 'as agreed between the parties',
					},
				]"
				input-cols="6"
				select
				label="When will this communication occur?"
				other
				:testid="'parenting-agreement-facilitated-communication-when'"
				:length="'long'"
				:disabled="isViewMode"
			/>
		</conditional-group>
		<additional-clauses
			:title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2"
			v-model="parenting.additionalClausesParentingAgreementsToRecitals" :testid="'parenting-agreement-add-clause-to-recitals-'"
			:disabled="isViewMode"
		/>
		<additional-clauses
			:title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2"
			v-model="parenting.additionalClausesParentingAgreements" :testid="'parenting-agreement-add-clause-'"
			:disabled="isViewMode"
		/>
	</b-card>
</template>
